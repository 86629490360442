<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-08 15:45:28
-->
<template>
    <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
    components: {
        SideBar
    },

    data() {
        return {
            groups: [
                {
                    label: '',
                    list: [
                        {
                            name: '授权管理',
                            path: '/admin/upgrade/license'
                        },
						{
						    name: '系统升级',
						    path: '/admin/upgrade/upgrade'
						},
						{
						    name: '系统修复',
						    path: '/admin/upgrade/repair'
						},
						{
						    name: '更新日志',
						    path: '/admin/upgrade/journal'
						}
                    ]
                }
            ]
        };
    }
};
</script>
